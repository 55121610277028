import React from "react";

const Song = ({ currentSong }) => {
  return (
    <div className="song-container">
      <img alt={currentSong.name} src={currentSong.cover} />
      <img class="blur" alt={currentSong.name} src={currentSong.cover} />

      <span class="dot"></span>
      <h2>{currentSong.name}</h2>
      <h3>{currentSong.artist}</h3>
    </div>
  );
};
export default Song;
